"use client";
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "next/navigation";
import { v4 } from "uuid";

import { GlobalPathParams } from "~/app/[locale]/types";
import { HeroBannerProps } from "~/bff/components/HeroBanner";
import { ArticlePage } from "~/bff/types/ArticlePage";
import { Link } from "~/bff/types/Link";
import { CombinedLink } from "~/components/combined-link/component";
import { CTALinkButton } from "~/components/cta-link-button/component";
import { Image } from "~/components/image/component";
import { getLinkUrl } from "~/helpers/locales";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { EvergageContentZones } from "~/lib/salesforce/evergage/types";
import { TextColor } from "~/types/general.types";
import { getUrlSlugFromDeliveryKey } from "~/utils/get-url-slug-from-delivery-key";
import { getImageSrc } from "~/utils/image/get-image-src";

import { HERO_BANNER_CLASSES, imageTemplates } from "./constants";
import { HeroBannerContainer } from "./styled";

const COMPONENT_ID = "hero-banner";

export const HeroBanner: React.FC<HeroBannerProps> = ({
  children,
  title,
  text: initialText,
  description: initialDesc,
  link: initialLink,
  image: initialImage,
  textColor,
  linkedArticle,
  testAutomationId = COMPONENT_ID,
}) => {
  const { locale } = useParams<GlobalPathParams>();

  const { linkedBanner } = linkedArticle?.props || {};
  const { _meta } = (linkedArticle as ArticlePage) || {};

  const initialHeroBanner = {
    image: initialImage,
    text: initialText,
    description: initialDesc,
    link: initialLink,
  };

  const articleUrl = getUrlSlugFromDeliveryKey(
    getLinkUrl(locale, _meta?.deliveryKey ?? ""),
    locale,
  );

  const linkedArticleUrl: Link = {
    url: articleUrl,
    label: linkedBanner?.linkText,
  };

  const linkedBannerValues = {
    image: linkedBanner?.image,
    text: linkedBanner?.heading,
    description: linkedBanner?.description,
    link: linkedArticleUrl,
  };

  const banner = _meta?.deliveryKey ? linkedBannerValues : initialHeroBanner;
  const id = title
    ? `${testAutomationId}-${title?.replace(" ", "-").toLowerCase()}`
    : testAutomationId;

  const { image, link, text, description } = banner;

  return (
    <HeroBannerContainer
      {...getTestAutomationProps(testAutomationId)}
      data-gcp-element={COMPONENT_ID}
      data-evergage-zone={EvergageContentZones.HERO_BANNER}
      textColor={textColor ?? ""}
      id={id ?? ""}
    >
      <Box
        className={HERO_BANNER_CLASSES.banner}
        id={`${COMPONENT_ID}-${link?.url}`}
      >
        <CombinedLink
          key={v4()}
          href={link?.url ?? ""}
          className={HERO_BANNER_CLASSES.backgroundLink}
          underline="none"
          display="block"
        >
          <Image
            className={HERO_BANNER_CLASSES.backgroundImage}
            imageTemplates={imageTemplates}
            src={getImageSrc(image?.source ?? undefined)}
            alt={prepareTextAttribute(image?.alt, true)}
            title={prepareTextAttribute(image?.title)}
            fetchPriority="high"
          />
        </CombinedLink>
        <Box
          className={HERO_BANNER_CLASSES.textBlock}
          {...getTestAutomationProps(`info-block`)}
        >
          <CombinedLink
            key={v4()}
            className={HERO_BANNER_CLASSES.infoLink}
            href={link?.url ?? ""}
            underline="none"
            display="block"
          >
            {!_meta?.deliveryKey && (
              <Typography
                variant="h1ExtraSmall"
                className={HERO_BANNER_CLASSES.title}
                {...getTestAutomationProps(`category-title`)}
              >
                {title}
              </Typography>
            )}
            <Typography
              {...getTestAutomationProps(`title`)}
              variant="h2"
              className={HERO_BANNER_CLASSES.header}
            >
              {text}
            </Typography>
            <Typography
              component="p"
              variant="h6"
              {...getTestAutomationProps(`description`)}
            >
              {description}
            </Typography>
          </CombinedLink>

          <CTALinkButton
            href={link?.url ?? ""}
            linkTextClassName={HERO_BANNER_CLASSES.ctaLinkText}
            textColor={textColor as TextColor}
          >
            {link?.label ?? ""}
          </CTALinkButton>
        </Box>
      </Box>
      {children as React.ReactNode}
    </HeroBannerContainer>
  );
};
