"use client";
import React from "react";

import Typography from "@mui/material/Typography";
import NextLink from "next/link";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { BrowseCollectionBannerProps } from "~/bff/components/BrowseCollectionBanner";
import { CombinedLink } from "~/components/combined-link/component";
import { CTALinkButton } from "~/components/cta-link-button/component";
import { Image } from "~/components/image/component";
import { getLinkUrl } from "~/helpers/locales";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { EvergageContentZones } from "~/lib/salesforce/evergage/types";
import { TextColor } from "~/types/general.types";
import { getImageSrc } from "~/utils/image/get-image-src";

import { BROWSE_COLLECTION_BANNER_CLASSES, imageTemplates } from "./constants";
import { BrowseCollectionBannerContainer } from "./styled";

export const BrowseCollectionBanner: React.FC<BrowseCollectionBannerProps> = ({
  title,
  text,
  link,
  image,
  textColor = "light",
  testAutomationId = "browse-collection-banner",
}) => {
  const { locale } = useParams<GlobalPathParams>();

  const id = title
    ? `${testAutomationId}-${title.replace(/ /g, "-").toLowerCase()}`
    : testAutomationId;

  return (
    <BrowseCollectionBannerContainer
      {...getTestAutomationProps(testAutomationId)}
      data-evergage-zone={EvergageContentZones.COLLECTION_BANNER}
      isWhite={textColor === "light"}
      id={id ?? ""}
    >
      <CombinedLink
        href={link?.url ?? ""}
        className={BROWSE_COLLECTION_BANNER_CLASSES.imageWrapper}
      >
        <Image
          className={BROWSE_COLLECTION_BANNER_CLASSES.image}
          imageTemplates={imageTemplates}
          src={getImageSrc(image?.source ?? undefined)}
          alt={prepareTextAttribute(image?.alt, true)}
          title={prepareTextAttribute(image?.title)}
          fetchPriority="high"
        />
      </CombinedLink>
      <CombinedLink
        href={link?.url ?? ""}
        className={BROWSE_COLLECTION_BANNER_CLASSES.infoLink}
        underline="none"
        display="block"
      >
        <Typography
          className={BROWSE_COLLECTION_BANNER_CLASSES.title}
          variant="h2"
          {...getTestAutomationProps("title")}
        >
          {title}
        </Typography>
        {text && (
          <Typography
            className={BROWSE_COLLECTION_BANNER_CLASSES.description}
            align="center"
            component="p"
            variant="h6"
            {...getTestAutomationProps("description")}
          >
            {text}
          </Typography>
        )}
      </CombinedLink>

      <NextLink
        prefetch={false}
        href={getLinkUrl(locale, link?.url ?? "")}
        locale={false}
        passHref
        legacyBehavior
      >
        <CTALinkButton textColor={textColor as TextColor}>
          {link?.label ?? ""}
        </CTALinkButton>
      </NextLink>
    </BrowseCollectionBannerContainer>
  );
};
