import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "banner-mobile",
  [WindowFormat.tablet]: "banner-tablet",
  [WindowFormat.desktop]: "banner-regulardesktop",
  [WindowFormat.wideDesktop]: "banner-largedesktop",
};

export const HERO_BANNER_PREFIX = "HeroBanner";

export const HERO_BANNER_CLASSES = {
  banner: `${HERO_BANNER_PREFIX}-banner`,
  title: `${HERO_BANNER_PREFIX}-title`,
  textBlock: `${HERO_BANNER_PREFIX}-textBlock`,
  header: `${HERO_BANNER_PREFIX}-header`,
  backgroundLink: `${HERO_BANNER_PREFIX}-backgroundLink`,
  infoLink: `${HERO_BANNER_PREFIX}-infoLink`,
  backgroundImage: `${HERO_BANNER_PREFIX}-backgroundImage`,
  ctaLinkText: `${HERO_BANNER_PREFIX}-ctaLinkText`,
};
