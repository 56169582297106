"use client";

import React, { PropsWithChildren } from "react";

import { ButtonProps } from "@mui/material/Button/Button.d";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useLink } from "~/hooks/use-link/hook";
import { COLOR_PRIMARY_WHITE } from "~/theme/colors";
import { TextColor } from "~/types/general.types";

import { CTA_LINK_BUTTON_CLASSES } from "./constants";
import { CTALinkButtonStyled } from "./styled";

interface CTALinkButtonProps extends ButtonProps {
  textColor?: TextColor;
  linkTextClassName?: string;
}

export const CTALinkButton = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<CTALinkButtonProps>
>((props, ref) => {
  const { locale } = useParams<GlobalPathParams>();

  const { className, linkTextClassName, textColor, href, ...baseProps } = props;
  const currentTextColor = textColor === "light" ? COLOR_PRIMARY_WHITE : "unset";
  const { shouldDisableLink } = useLink(href ?? "", locale);

  return (
    <CTALinkButtonStyled
      {...baseProps}
      href={shouldDisableLink ? undefined : href}
      {...(shouldDisableLink && {
        "data-disabled": true,
        role: "presentation",
        tabIndex: -1,
        "aria-disabled": true,
      })}
      className={className}
      ref={ref}
      {...getTestAutomationProps("button")}
    >
      <Typography
        style={{ color: currentTextColor }}
        className={clsx(CTA_LINK_BUTTON_CLASSES.linkText, linkTextClassName)}
      >
        {props.children}
      </Typography>
    </CTALinkButtonStyled>
  );
});

CTALinkButton.displayName = "CTALinkButton";
