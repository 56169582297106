"use client";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import { TRANSITION_DURATION_200 } from "~/constants/common";
import {
  COLOR_PRIMARY_BLACK,
  COLOR_BLUE_BRAND_50,
  COLOR_PRIMARY,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";
import { ARIAL_BLACK_FONT_FAMILY } from "~/theme/utils/font.constants";

import { CTA_LINK_BUTTON_CLASSES } from "./constants";

export const CTALinkButtonStyled = styled(Button)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5, 0.75, 1.5, 0),
    height: "unset",
    width: "fit-content",
    "&:hover": {
      background: "none",
    },
    "&[data-disabled='true']": {
      cursor: "default",
      "& p:hover::after": {
        height: "8px",
      },
      "& p:active::after": {
        background: COLOR_BLUE_BRAND_50,
      },
    },
    [`& .${CTA_LINK_BUTTON_CLASSES.linkText}`]: {
      position: "relative",
      width: "fit-content",
      fontFamily: ARIAL_BLACK_FONT_FAMILY,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold as number,
      letterSpacing: "0.03em",
      textTransform: "uppercase",
      color: COLOR_PRIMARY_BLACK,
      zIndex: 2,
      [formatMedia.BREAKPOINT_TABLET]: { fontSize: 18 },

      "&::after": {
        position: "absolute",
        content: '" "',
        bottom: 0,
        right: -6,
        width: "100%",
        height: "8px",
        background: COLOR_BLUE_BRAND_50,
        zIndex: -1,
        transition: TRANSITION_DURATION_200,
      },

      "&:hover": {
        "&::after": {
          height: "100%",
        },
      },

      "&:active": {
        "&::after": {
          background: COLOR_PRIMARY,
        },
      },
    },
  };
});
