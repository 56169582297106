import React from "react";

import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { COLOR_BLACK, COLOR_GREYSCALE_BLACK_75, COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { HERO_BANNER_CLASSES } from "./constants";

interface StylesProps {
  textColor: string;
}

export const HeroBannerContainer = styled(
  ({ textColor, ...restProps }: StylesProps & BoxProps) => <Box {...restProps} />,
)(({ theme, textColor }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    marginBottom: theme.spacing(7),
    [formatMedia.BREAKPOINT_TABLET]: {
      marginBottom: theme.spacing(9),
    },
    [formatMedia.BREAKPOINT_DESKTOP]: {
      marginBottom: theme.spacing(16),
    },
    [`& .${HERO_BANNER_CLASSES.banner}`]: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      height: "500px",
      backgroundColor:
        textColor === "light" ? COLOR_GREYSCALE_BLACK_75 : COLOR_WHITE,

      [formatMedia.BREAKPOINT_TABLET]: {
        height: "550px",
      },
    },
    [`& .${HERO_BANNER_CLASSES.title}`]: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(0.5),
    },
    [`& .${HERO_BANNER_CLASSES.textBlock}`]: {
      zIndex: 1,
      marginLeft: theme.spacing(2),

      color: textColor === "light" ? COLOR_WHITE : COLOR_BLACK,

      [formatMedia.BREAKPOINT_TABLET]: {
        marginLeft: theme.spacing(4),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginLeft: "12.4%",
      },
      [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
        marginLeft: "16.1%",
      },

      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(2),

        [formatMedia.BREAKPOINT_TABLET]: {
          marginBottom: theme.spacing(3),
        },
      },
    },
    [`& .${HERO_BANNER_CLASSES.header}`]: {
      ...theme.typography.h3,
      textTransform: "inherit",
      [formatMedia.BREAKPOINT_TABLET]: {
        ...theme.typography.h2,
        overflowWrap: "normal",
        textTransform: "inherit",
      },
    },
    [`& .${HERO_BANNER_CLASSES.backgroundLink}`]: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    [`& .${HERO_BANNER_CLASSES.infoLink}`]: {
      zIndex: 1,
    },
    [`& .${HERO_BANNER_CLASSES.backgroundImage}`]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [`& p.${HERO_BANNER_CLASSES.ctaLinkText}`]: {
      fontSize: 18,
    },
  };
});
