import dynamic from "next/dynamic";

import { ArticlePage as ArticlePageType } from "~/bff/components/ArticlePage";
import { ArticleTile as ArticleTileType } from "~/bff/components/ArticleTile";
import {
  BffComponentType as Component,
  ComponentsConfig,
} from "~/bff/ComponentsConfig";
import { importComponentByName } from "~/helpers/import-component-by-name";

const ClickAndCollectInterrupterPanel = dynamic(
  async () =>
    importComponentByName(
      import("~/components/click-and-collect-interrupter-panel/component"),
      "ClickAndCollectInterrupterPanel",
    ),
);

const CollectionCarousel = dynamic(
  async () =>
    importComponentByName(
      import("~/components/carousel/components/collection-carousel/component"),
      "CollectionCarousel",
    ),
);
const ProductCard = dynamic(
  async () =>
    importComponentByName(
      import("~/components/product-card/component"),
      "ProductCard",
    ),
);

const GenericTextImageBanner = dynamic(
  async () =>
    importComponentByName(
      import("~/components/generic-text-image-banner/component"),
      "GenericTextImageBanner",
    ),
);

const NewIn = dynamic(
  async () =>
    importComponentByName(import("~/components/new-in/component"), "NewIn"),
);
const EditorialContent = dynamic(
  async () =>
    importComponentByName(
      import("~/components/editorial-content/component"),
      "EditorialContent",
    ),
);
const EditorialCard = dynamic(
  async () =>
    importComponentByName(
      import("~/components/editorial-content/components/editorial-card/component"),
      "EditorialCard",
    ),
);
const RelatedEditorialCard = dynamic(
  async () =>
    importComponentByName(
      import("~/components/related-editorial-card/component"),
      "RelatedEditorialCard",
    ),
);
const ArticleCards = dynamic(
  async () =>
    importComponentByName(
      import("~/components/article-cards/component"),
      "ArticleCards",
    ),
);
const ArticleTile = dynamic(
  async () =>
    importComponentByName(
      import("~/components/article-tile/component"),
      "ArticleTile",
    ),
);

export const clpPlpGcpCommonConfig: ComponentsConfig = {
  [Component.COLLECTIONS_CAROUSEL]: {
    component: CollectionCarousel,
    // TODO: It's technical debt, we have availability to
    //  implement component without this parameter.
    stopChildrenPreparation: true,
  },
  [Component.NEW_IN]: {
    component: NewIn,
  },
  [Component.PRODUCT_ITEM]: {
    component: ProductCard,
  },
  [Component.GENERIC_BANNER]: {
    component: GenericTextImageBanner,
  },
  [Component.RELATED_EDITORIAL_CONTENT]: {
    component: EditorialContent,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: RelatedEditorialCard as ArticlePageType,
      },
      // ArticleTile now is used for backward compatibility, later on EditorialContent only ArticlePage will be used
      [Component.ARTICLE_TILE]: {
        component: RelatedEditorialCard as ArticleTileType,
      },
    },
  },
  [Component.ARTICLE_CARDS]: {
    component: ArticleCards,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: ArticleTile as ArticlePageType,
      },
      // ArticleTile is used for backward compatibility
      [Component.ARTICLE_TILE]: {
        component: ArticleTile as ArticleTileType,
      },
    },
  },
  [Component.EDITORIAL_CONTENT]: {
    component: EditorialContent,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: EditorialCard as ArticlePageType,
      },
      // ArticleTile now is used for backward compatibility, later on EditorialContent only ArticlePage will be used
      [Component.ARTICLE_TILE]: {
        component: EditorialCard as ArticleTileType,
      },
    },
  },
  [Component.ARTICLE_TILE]: {
    component: ArticleTile as ArticleTileType,
  },
  [Component.CLICK_COLLECT_INTERRUPTER_PANEL]: {
    component: ClickAndCollectInterrupterPanel,
  },
};
