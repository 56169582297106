import { BreadcrumbsItem } from "~/bff/types/BreadcrumbsItem";
import { LOCALES } from "~/constants/i18n";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Nullable } from "~/types/general.types";

import { JSON_LD_CONTEXT, JsonLdType } from "../constants";

export const getJsonLdForBreadcrumbs = (
  breadcrumbs: Nullable<BreadcrumbsItem>[] | undefined,
  locale: LOCALES,
): string => {
  const baseUrl = AzureConfigurator?.getConfig(locale)?.frontEndBaseUrl;
  const data = {
    "@context": JSON_LD_CONTEXT,
    "@type": JsonLdType.BREAD_CRUMB_LIST,
    itemListElement: breadcrumbs?.map((breadcrumb, index) => {
      return {
        "@type": JsonLdType.LIST_ITEM,
        position: index + 1,
        name: breadcrumb?.title,
        item: `${baseUrl}/${locale}/${breadcrumb?.urlSlug}`,
      };
    }),
  };

  return JSON.stringify(data);
};
