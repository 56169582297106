import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "banner-mobile",
  [WindowFormat.tablet]: "banner-tablet",
  [WindowFormat.desktop]: "banner-regulardesktop",
  [WindowFormat.wideDesktop]: "banner-largedesktop",
};

export const BROWSE_COLLECTION_BANNER_PREFIX = "BrowseCollectionBanner";

export const BROWSE_COLLECTION_BANNER_CLASSES = {
  infoLink: `${BROWSE_COLLECTION_BANNER_PREFIX}-infoLink`,
  imageWrapper: `${BROWSE_COLLECTION_BANNER_PREFIX}-imageWrapper`,
  image: `${BROWSE_COLLECTION_BANNER_PREFIX}-image`,
  title: `${BROWSE_COLLECTION_BANNER_PREFIX}-title`,
  description: `${BROWSE_COLLECTION_BANNER_PREFIX}-description`,
};
