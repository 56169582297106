import React from "react";

import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import {
  COLOR_BLACK,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_PRIMARY_WHITE,
  COLOR_WHITE,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { BROWSE_COLLECTION_BANNER_CLASSES } from "./constants";

interface StylesProps {
  isWhite: boolean;
}

export const BrowseCollectionBannerContainer = styled(
  ({ isWhite, ...restProps }: StylesProps & BoxProps) => <Box {...restProps} />,
)(({ theme, isWhite }) => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  return {
    position: "relative",
    backgroundSize: "cover",
    backgroundColor: isWhite ? COLOR_GREYSCALE_BLACK_75 : COLOR_WHITE,
    padding: theme.spacing(3),
    height: 500,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: theme.spacing(7),
    [BREAKPOINT_TABLET]: {
      marginBottom: theme.spacing(9),
      height: 550,
    },
    [BREAKPOINT_DESKTOP]: {
      marginBottom: theme.spacing(16),
    },
    [`& .${BROWSE_COLLECTION_BANNER_CLASSES.infoLink}`]: {
      zIndex: 1,
    },
    [`& .${BROWSE_COLLECTION_BANNER_CLASSES.imageWrapper}`]: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    [`& .${BROWSE_COLLECTION_BANNER_CLASSES.image}`]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [`& .${BROWSE_COLLECTION_BANNER_CLASSES.title}`]: {
      ...theme.typography.h3,
      textTransform: "inherit",
      paddingBottom: theme.spacing(1),
      color: isWhite ? COLOR_PRIMARY_WHITE : COLOR_BLACK,
      zIndex: 1,
      [BREAKPOINT_TABLET]: {
        paddingBottom: theme.spacing(2),
        ...theme.typography.h2,
        textTransform: "inherit",
      },
      [BREAKPOINT_DESKTOP]: {
        overflowWrap: "normal",
      },
    },
    [`& .${BROWSE_COLLECTION_BANNER_CLASSES.description}`]: {
      paddingBottom: theme.spacing(2.5),
      color: isWhite ? COLOR_PRIMARY_WHITE : COLOR_BLACK,
      zIndex: 1,
      ...theme.typography.body1,
      [BREAKPOINT_TABLET]: {
        paddingBottom: theme.spacing(3.5),
        ...theme.typography.h6,
      },
    },
  };
});
